<template>
	<div class="animated fadeIn">
		<template v-if="$route.name === 'TotalEarningsDetail'">
			<b-alert
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							v-model="filters.username"
							:init-value="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role: 'distributor'}"
							class="mb-3"
							q="label"
							return-key="username" />
						<div class="row">
							<div class="col">
								<b-button
									variant="primary"
									class="btn mx-1"
									@click="getDataFiltered">
									{{ translate('search') }}
								</b-button>
								<b-button
									variant="secondary"
									class="btn mx-1"
									@click="clearFilters">
									{{ translate('clear') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-alert>
		</template>
		<template v-else>
			<div
				class="row">
				<div class="col">
					<div
						:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
						class="btn mx-1 bg-primary-alt"
						@click="toggleFilters">
						<i
							class="fa fa-filter"
							aria-hidden="true" />
					</div>
					<b-button
						:disabled="loading"
						class="float-right"
						variant="success"
						@click="downloadExcel">
						<i :class="!loading ? 'fas fa-file-excel' : 'fas fa-spinner fa-spin'" /> {{ translate('export') }}
					</b-button>
				</div>
			</div>
			<hr>
			<data-filter
				:display="showFilters"
				get-by="name"
				@submit="getDataFiltered"
				@clear="clearFilters">
				<template slot="form">
					<div class="row">
						<div
							:class="sizeCol"
							class="form-group">
							<label
								for="organization"
								class="label">{{ translate('organization') }}</label>
							<select
								id="organization"
								v-model="filters.organization"
								name="organization"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option value="leaders">
									{{ translate('only_leaders') }}
								</option>
								<option
									v-for="(leader, index) in leaders"
									:key="index"
									:value="leader.id">
									{{ leader.attributes.leader }}
								</option>
							</select>
						</div>
						<div
							class="form-group"
							:class="sizeCol">
							<label
								for="uid"
								class="label">{{ translate('user_id') }}</label>
							<input
								id="uid"
								v-model="filters.uids"
								:placeholder="translate('type_uid')"
								type="text"
								name="uids"
								class="form-control">
						</div>
						<div
							class="form-group"
							:class="sizeCol">
							<label
								for="countryName"
								class="label">{{ translate('country') }}</label>

							<select
								id="countryName"
								v-model="filters.country"
								name="country"
								class="form-control">
								<option
									:value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="country in countries"
									:key="country.attributes.code"
									:value="country.attributes.code">
									{{ translate(country.attributes.code.toLowerCase()) }}
								</option>
							</select>
						</div>
						<div
							:class="sizeCol">
							<div
								class="form-group">
								<label
									for="dateRange"
									class="label">{{ translate('date_range') }}</label>
								<select
									id="dateRange"
									v-model="dateRange"
									name="dateRange"
									class="form-control">
									<option
										v-for="dateRangeInfo in dateRanges"
										:key="dateRangeInfo"
										:value="dateRangeInfo">
										{{ translate(dateRangeInfo) }}
									</option>
								</select>
							</div>
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							:class="sizeCol">
							<div
								class="form-group">
								<label
									for="startDate"
									class="label">{{ translate('start_date') }}</label>
								<v-date-picker
									id="startDate"
									v-model="startDate"
									:input-props="{ class: 'form-control' }"
									:formats="calendarConfig"
									name="startDate"
									show-caps />
							</div>
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							:class="sizeCol">
							<div
								class="form-group">
								<label
									for="endDate"
									class="label">{{ translate('end_date') }}</label>
								<v-date-picker
									id="endate"
									v-model="endDate"
									:input-props="{ class: 'form-control' }"
									:formats="calendarConfig"
									name="endDate"
									show-caps />
							</div>
						</div>
					</div>
				</template>
			</data-filter>
		</template>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<router-link
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name, query }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
						<template
							v-if="tabIndex === index"
							class="p-0">
							<router-view />
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import DataFilter from '@/components/DataFilter';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import { PAGINATION } from '@/settings/RequestReply';
import {
	Report as CO,
	Grids,
	Tooltip,
	TransactionCategories,
	Countries as countriesMessages,
} from '@/translations';
import { SEARCH_USERS } from '@/config/endpoint';
import {
	custom,
	PERIOD_FILTERS as dateRanges,
} from '@/settings/Dates';
import Reports from '@/util/Report';
import Leaders from '@/util/Leaders';
import Countries from '@/util/Country';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'TotalEarningsSummary',
	components: { DataFilter },
	mixins: [FiltersParams, DashboardRedirect],
	messages: [CO, Grids, Tooltip, TransactionCategories, countriesMessages],
	data() {
		return {
			dateRanges,
			custom,
			loading: false,
			dateRange: dateRanges.all_time,
			leadersData: new Leaders(),
			countriesData: new Countries(),
			leaders: [],
			countries: [],
			endpoint,
			tabIndex: 0,
			tabs: [
				{
					name: 'TotalEarningsSummary',
					translate_key: 'total_earnings_summary',
				},
				{
					name: 'TotalEarningsDetail',
					translate_key: 'total_earnings_detail',
				},
			],
		};
	},
	computed: {
		query() {
			const tempQuery = this.$route.query;
			if (this.$route.name !== 'TotalEarningsSummary') {
				delete tempQuery.organization;
				delete tempQuery.country;
				delete tempQuery.dateRange;
			}
			return tempQuery;
		},
		sizeCol() {
			if (this.dateRange === this.dateRanges.custom) {
				return 'col-md-4 col-sm-12 col-xs-12';
			}

			return 'col-md-3 col-sm-12 col-xs-12';
		},
	},
	mounted() {
		this.leadersData.getLeaders().then((response) => {
			this.leaders = response;
		});
		this.countriesData.getCountries().then((response) => {
			this.countries = response;
		});
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			Object.keys(options).forEach((key) => {
				if (typeof options[key] === 'undefined' || options[key] === null || options[key].length === 0) {
					delete options[key];
				}
			});

			this.$router.push({ name: this.$route.name, query: options });
		},
		toggleFilters() {
			this.showFilters = !this.showFilters;
		},
		downloadExcel() {
			const options = { ...this.query };
			const totalEarnings = new Reports();
			this.loading = true;

			totalEarnings.downloadTotalEarningsReport(options).then(() => {
				const { response } = totalEarnings.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('total_earnings_summary')}.xlsx`);
				this.$el.appendChild(link);
				link.click();
				this.loading = false;
			}).catch(() => {
				this.loading = false;
			});
		},
	},
};
</script>
